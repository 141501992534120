import { useState, useEffect } from 'react';

import { Grid, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import Headline from '@/components/adView/common/Headline';
import { Product } from '@/components/adView/types';
import AdCard from '@/components/common/AdCard';
import getAdCardProduct from '@/components/common/AdCard/helpers/getAdCardProduct';
import PlaceHolderCard from '@/components/common/AdCard/PlaceHolderCard';
import { LastSeenAdsProps } from '@/components/home/LastSeenAds/type';
import { useUserContext } from '@/contexts/User/UserContext';
import { useVerticalHomeContext } from '@/contexts/VerticalHome/VerticalHomeContext';
import { fetchAds } from '@/data/client/ads';
import debounce from '@/helpers/debounce';
import transformFriendlyUrlToDev from '@/helpers/product/transformFriendlyUrlToDev';
import 'swiper/css';
import 'swiper/css/navigation';

const LastSeenAds: React.FunctionComponent<LastSeenAdsProps> = ({ layoutType, showLocation }) => {
  type functionType = (args: {
    listIds: Array<number>,
  }) => void;
  const { userState } = useUserContext();
  const { verticalHome } = useVerticalHomeContext();
  const [ads, setAds] = useState<Product[]>([]);
  const theme = useTheme();
  const { lastSeenAdIds, vertical } = verticalHome;
  const [key, setKey] = useState(0);
  const [adsQueued, setAdsQueued] = useState(false);
  const blankSlideClassName = 'last-seen-ads-blank-slide';

  const getAds: functionType = async ({ listIds }) => {
    // Had to do it like this, otherwise it runs in a loop
    if (!adsQueued && listIds.length > 0) {
      await fetchAds({
        query: {
          list_id: listIds.join(','),
          limit: 24,
        },
      }).then((response) => {
        const tempArray: Array<Product> = [];
        // sorting needs to be done on the client side because the API has different sotring
        // and there is no way to tell it otherwise
        if (response.length > 0) {
          listIds.forEach((lastSeenListId) => {
            response.forEach((ad: Product) => {
              if (lastSeenListId === ad.list_id) {
                tempArray.push({
                  ...ad,
                  url: transformFriendlyUrlToDev({ link: ad.url }),
                });
              }
            });
          });
        }
        setAdsQueued(true);

        setAds(tempArray);
      });
    }
  };

  getAds({ listIds: lastSeenAdIds });

  const renderBlankSlides = (): void => {
    const blankSlides = document.querySelectorAll(`.${blankSlideClassName}`);

    if (blankSlides.length > 0) {
      blankSlides?.forEach((slide) => {
        const container = slide as Element;
        if (!container.hasChildNodes()) {
          const root = createRoot(container!);
          root.render(<PlaceHolderCard layoutType={layoutType} showLocation={showLocation} />);
        }
      });
    }
  };

  useEffect(() => {
    // This is to force a rerender on window resize
    // because loopAddBlankSlides Swiper prop is not responsive
    const handleResize = (): void => {
      setKey((prevKey) => prevKey + 1);
    };

    window.addEventListener('resize', debounce(handleResize));

    return (): void => {
      window.removeEventListener('resize', debounce(handleResize));
    };
  }, []);

  useEffect(() => {
    renderBlankSlides();
  });

  if (!userState.account || !lastSeenAdIds?.length || ads.length === 0) {
    return null;
  }

  return (
    <Container>
      <Grid item>
        <Headline>Legutóbb ezeket nézted</Headline>
        <Swiper
          suppressHydrationWarning
          key={key}
          className="custom-swiper big-nav-buttons"
          data-testid="last-seen-ads-slider"
          modules={[Navigation]}
          loop
          slideBlankClass={blankSlideClassName}
          breakpoints={{
            [theme.breakpoints.values.xs]: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: theme.spacing(2),
            },
            [theme.breakpoints.values.sm]: {
              slidesPerView: 4,
              slidesPerGroup: 4,
              spaceBetween: theme.spacing(2),
            },
            [theme.breakpoints.values.lg]: {
              slidesPerView: 6,
              slidesPerGroup: 6,
              spaceBetween: theme.spacing(4),
            },
          }}
          style={{ paddingBottom: '2px' }}
          navigation={{ disabledClass: 'navigation-disabled' }}
        >
          {
            // max 12 ads
            ads?.map((product, itemIndex) => itemIndex <= 11 && (
              <SwiperSlide suppressHydrationWarning key={product.list_id}>
                <AdCard
                  product={getAdCardProduct(product)}
                  layoutType={vertical === 'job' ? 'job' : undefined}
                  itemIndex={itemIndex}
                  trackingParams="#pos=last_viewed_ads&opt=homepage"
                  showLocation={showLocation}
                />
              </SwiperSlide>
            ))
          }
        </Swiper>
      </Grid>
    </Container>
  );
};

export default LastSeenAds;
