import {
  Box, Card, CardMedia, CardContent,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { PlaceHolderCardProps } from './types';

const PlaceHolderCard: React.FunctionComponent<PlaceHolderCardProps> = (
  { layoutType, showLocation },
) => {
  const theme = useTheme();

  const SkeletonBar = styled((props) => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        borderRadius: layoutType === 'job' ? 0 : '4px',
        backgroundColor: theme.palette.action.hover,
      }}
      {...props}
    />
  ))({});

  const renderJobSkeleton = (): JSX.Element => (
    <>
      <SkeletonBar sx={{ height: '14px', mb: 0.5, mt: 1 }} />
      <SkeletonBar sx={{ height: '14px', width: '90%', mb: 1 }} />
      <Box display="flex">
        <SkeletonBar
          sx={{
            height: '16px', width: '16px', mb: 1, mr: 1,
          }}
        />
        <SkeletonBar sx={{ height: '14px', mb: 1 }} />
      </Box>
      <Box display="flex">
        <SkeletonBar
          sx={{
            height: '16px', width: '16px', mb: 1, mr: 1,
          }}
        />
        <SkeletonBar sx={{ height: '14px', mb: 1 }} />
      </Box>
      <Box mb={1} display="flex">
        <img
          src="/assets/svg/jfg_logo_transparent.svg"
          alt=""
          width="70px"
          height="24px"
          loading="lazy"
        />
      </Box>
      <SkeletonBar sx={{ height: '12px', width: '66%' }} />
    </>
  );

  return (
    <Card
      sx={{
        minHeight: '100%',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}
    >
      {
        layoutType !== 'job'
        && (
          <Box sx={{ p: 1 }}>
            <Box sx={{
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: theme.palette.action.hover,
            }}
            >
              <CardMedia
                data-hj-suppress
                sx={{
                  aspectRatio: '4/3',
                  objectFit: 'none',
                  width: '100%',
                  height: 'auto',
                }}
                component="img"
                loading="lazy"
                image="/assets/svg/jfg_logo_transparent.svg"
              />
            </Box>
          </Box>
        )
      }
      <CardContent
        sx={{
          px: 1,
          '&:last-child': { pb: 1 },
          pt: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: '1',
        }}
      >
        {layoutType === 'job' ? (
          renderJobSkeleton()
        ) : (
          <>
            <SkeletonBar sx={{ height: '14px', mb: 0.5 }} />
            <SkeletonBar sx={{ height: '14px', mb: 1 }} />
            <SkeletonBar sx={{ height: '16px', mt: '2px', mb: showLocation ? '23px' : '2px' }} />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default PlaceHolderCard;
