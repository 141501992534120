import { Grid } from '@mui/material';
import dynamic from 'next/dynamic';

import { RealestateHomeProps } from '../types/realestate';
import Bluebar from '@/components/common/Bluebar';
import ComponentErrorBoundary from '@/components/common/ComponentErrorBoundary/ComponentErrorBoundary';
import Head from '@/components/common/Head';
import ActionCards from '@/components/home/ActionCards/ActionCards';
import FreshAds from '@/components/home/FreshAds/FreshAds';
import Hero from '@/components/home/Hero/Hero';
import LastSeenAds from '@/components/home/LastSeenAds/LastSeenAds';
import JfgShops from '@/components/home/Shops/index.server';
import Tiles from '@/components/home/Tiles/Tiles';
import VerticalBackground from '@/components/home/VerticalBackground/VerticalBackground';
import SearchBox from '@/components/landing/RealEstate/SearchBox';
import { realEstateHomeUrl } from '@/config';
import { useVerticalHomeContext } from '@/contexts/VerticalHome/VerticalHomeContext';

const SearchForm = dynamic(
  () => import('@/components/landing/RealEstate/SearchForm'),
);
const JfgAdverticumZone = dynamic(
  () => import('@/components/marketing/AdverticumZone'),
);

const RealEstateHome = ({
  searchFields, freshAds,
}: RealestateHomeProps): React.ReactNode => {
  const { verticalHome } = useVerticalHomeContext();

  return (
    <>
      <ComponentErrorBoundary componentName="Head">
        <Head
          title="Jófogás Ingatlan - Eladó ingatlanok egy helyen"
          description="Házak és lakások széles választéka. Vásárolj ingatlant Magyarország legnagyobb online apróhirdetési oldalán!"
          canonical={realEstateHomeUrl}
          openGraph={{ url: realEstateHomeUrl }}
          productJsonLdData={{}}
        />
      </ComponentErrorBoundary>
      <ComponentErrorBoundary componentName="Bluebar">
        <Bluebar>
          <Bluebar.VerticalLinks active="ingatlan" channel="" />
        </Bluebar>
      </ComponentErrorBoundary>
      <VerticalBackground backgroundUrl="/assets/landing/realestate_vertical_pattern.svg">
        <ComponentErrorBoundary componentName="SearchBox">
          {searchFields && (
            <SearchBox>
              <SearchForm searchFields={searchFields} />
            </SearchBox>
          )}
        </ComponentErrorBoundary>
        <ComponentErrorBoundary componentName="ActionCards">
          <ActionCards />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary componentName="Hero">
          <Grid container>
            <Hero heroData={verticalHome.heroData} />
          </Grid>
        </ComponentErrorBoundary>
      </VerticalBackground>
      <Grid
        container
        flexDirection="column"
        alignItems="center"
        rowGap={4}
        pt={4}
        pb={8}
      >
        <ComponentErrorBoundary componentName="LastSeenAds">
          <LastSeenAds showLocation />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary componentName="JfgAdverticumZone">
          <JfgAdverticumZone pageType="vertical" zoneName="realestate_inbetween" displayOn="desktop" style={{ textAlign: 'center', minHeight: '110px' }} />
          <JfgAdverticumZone pageType="vertical" zoneName="realestate_inbetween" displayOn="mobile" style={{ textAlign: 'center', minHeight: '300px' }} />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary componentName="FreshAds">
          <FreshAds freshAds={freshAds} showLocation />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary componentName="Tiles">
          <Tiles />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary componentName="JfgShops">
          <JfgShops shops={verticalHome.shopData?.shops} />
        </ComponentErrorBoundary>
      </Grid>
    </>
  );
};

export default RealEstateHome;
